// components/KeyOfferings.js
import { useState } from 'react';
import Card from './Card';

// Import images
import luxuryCakesImage from '../../assets/images/luxury-cakes.png';
import heavenlyStrawberryImage from '../../assets/images/heavenly-strawberry.png';
import redVelvetImage from '../../assets/images/red-velvet.png';
import cupcakesImage from '../../assets/images/cupcakes.png';
import vanillaBlissImage from '../../assets/images/vanilla-bliss.png';
import chocolateIndulgenceImage from '../../assets/images/chocolate-indulgence.png';
import macaronsImage from '../../assets/images/macarons.png';
import raspberryDelightImage from '../../assets/images/raspberry-delight.png';
import passionFruitDreamImage from '../../assets/images/passion-fruit-dream.png';
import edibleDecorationsImage from '../../assets/images/edible-decorations.png';
import chocolateFigurinesImage from '../../assets/images/chocolate-figurines.png';
import chicoFigurinesImage from '../../assets/images/chico.png';
import bespokeCakesImage from '../../assets/images/buttercreamLuxury.png';

const KeyOfferings = () => {
  const [selectedOffering, setSelectedOffering] = useState(null);

  // Lightbox state to handle both card images & item images
  const [selectedLightbox, setSelectedLightbox] = useState(null);

  // Helper function to open the lightbox
  const openLightbox = (src, title = '', description = '') => {
    setSelectedLightbox({ src, title, description });
  };

  const offerings = [
    {
      category: 'Luxury Cakes',
      description: 'Exquisite cakes crafted with the finest ingredients.',
      image: luxuryCakesImage,
      items: [
        {
          name: 'Buttercream Cakes',
          description:
            'Savour our expertly made buttercream cakes, where creativity and deliciousness collide. Each cake is artistically frosted with delicate piping and smooth, velvety buttercream, showcasing the best ingredients and creating a delectable masterpiece with every mouthful.',
          image: heavenlyStrawberryImage,
        },
        {
          name: 'Luxury Bespoke Fondant Cakes',
          description:
            'Discover the sophistication of our Luxury Bespoke Fondant Cakes, professionally made with layers of luscious cake and rich filling covered in smooth, faultless fondant. Every cake, whether it has one or several layers, is made to be a gorgeous focal point for any event.',
          image: bespokeCakesImage,
        },
      ],
    },
    {
      category: 'Cupcakes',
      description: 'Delightful cupcakes perfect for any occasion.',
      image: cupcakesImage,
      items: [
        {
          name: 'Vanilla Bliss',
          description:
            'Moist vanilla cupcakes topped with creamy vanilla buttercream frosting.',
          image: vanillaBlissImage,
        },
        {
          name: 'Chocolate Indulgence',
          description:
            'Rich chocolate cupcakes with a velvety chocolate ganache frosting.',
          image: chocolateIndulgenceImage,
        },
      ],
    },
    {
      category: 'Macarons',
      description: 'Elegant and colourful macarons.',
      image: macaronsImage,
      items: [
        {
          name: 'Raspberry Delight',
          description: 'Macarons filled with a silky buttercream and a centre of homemade tangy raspberry filling.',
          image: raspberryDelightImage,
        },
        {
          name: 'Passion Fruit Dream',
          description: 'Exotic passion fruit macarons with a creamy filling.',
          image: passionFruitDreamImage,
        },
      ],
    },
    {
      category: 'Edible Cake Decorations',
      description: 'Handcrafted edible models and decorations for your cakes.',
      image: edibleDecorationsImage,
      items: [
        {
          name: 'Handcrafted Edible Figurines',
          description: 'Delicately handcrafted sugar figurines, designed with precision and artistry.',
          image: chicoFigurinesImage,
        },
        {
          name: 'Luxury Sugar Art Figurines',
          description: 'Handcrafted custom figurines sculpted from premium modelling chocolate.',
          image: chocolateFigurinesImage,
        },
      ],
    },
  ];

  return (
    <section id="products" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-center text-4xl font-heading text-accent mb-12">
          Our Key Offerings
        </h2>

        <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {offerings.map((offering, index) => (
            <Card
              key={index}
              className={`
                overflow-hidden flex flex-col 
                transition-transform transform 
                hover:shadow-xl hover:-translate-y-1
              `}
            >
              <div className="relative group perspective">
                <img
                  onClick={() =>
                    openLightbox(
                      offering.image,
                      offering.category,
                      offering.description
                    )
                  }
                  src={offering.image}
                  alt={offering.category}
                  className={`
                    object-cover w-full h-64
                    transform transition-transform duration-300
                    group-hover:scale-110 
                    group-hover:rotate-1 
                    group-hover:-translate-y-1
                    cursor-pointer
                  `}
                />
                <div
                  className={`
                    absolute inset-0 
                    bg-gradient-to-t from-black via-transparent 
                    opacity-50 group-hover:opacity-60 
                    transition-opacity duration-300
                  `}
                ></div>
                <h3
                  className={`
                    absolute bottom-4 left-4 
                    text-2xl font-heading text-white
                  `}
                >
                  {offering.category}
                </h3>
              </div>
              <div className="p-6 flex flex-col flex-grow">
                <p className="font-body text-retroGray mb-4 flex-grow">
                  {offering.description}
                </p>
                <button
                  onClick={() => setSelectedOffering(offering)}
                  className={`
                    mt-4 bg-accent text-white py-2 px-6 rounded-full 
                    shadow-lg transform transition 
                    duration-300 hover:-translate-y-1 
                    hover:bg-retroPink focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 
                    focus:ring-accent
                  `}
                >
                  Learn More
                </button>
              </div>
            </Card>
          ))}
        </div>
      </div>

      {/* Modal for showing offering details */}
      {selectedOffering && (
        <div
          className={`
            fixed inset-0 flex items-center justify-center 
            z-50 bg-black bg-opacity-50
          `}
          onClick={() => setSelectedOffering(null)}
        >
          <div
            className={`
              bg-white rounded-lg max-w-2xl w-full p-4 md:p-6 
              relative overflow-y-auto max-h-full
              transform transition-transform duration-300
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setSelectedOffering(null)}
              className={`
                absolute top-4 right-4 
                text-retroGray hover:text-accent 
                focus:outline-none text-2xl 
                leading-none
              `}
              aria-label="Close"
            >
              &#x2715;
            </button>
            <h3 className="text-2xl font-heading text-accent mb-4">
              {selectedOffering.category}
            </h3>
            <p className="font-body text-retroGray mb-6">
              {selectedOffering.description}
            </p>
            <div className="space-y-6">
              {selectedOffering.items.map((item, idx) => (
                <div key={idx} className="flex items-start space-x-4">
                  <img
                    onClick={() =>
                      openLightbox(item.image, item.name, item.description)
                    }
                    src={item.image}
                    alt={item.name}
                    className={`
                      w-24 h-24 object-cover 
                      rounded-lg shadow-md 
                      cursor-pointer transform 
                      transition-transform duration-300
                      hover:scale-105
                    `}
                  />
                  <div>
                    <h4 className="font-heading text-xl text-retroBlue">
                      {item.name}
                    </h4>
                    <p className="font-body text-retroGray">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={() => setSelectedOffering(null)}
              className={`
                mt-6 bg-retroPink text-white py-2 px-6 
                rounded-full shadow-lg transform transition
                duration-300 hover:-translate-y-1 
                hover:bg-accent focus:outline-none 
                focus:ring-2 focus:ring-offset-2 
                focus:ring-retroPink
              `}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Shared lightbox for both card images & item images */}
      {selectedLightbox && (
        <div
          className={`
            fixed inset-0 flex items-center 
            justify-center z-50 bg-black bg-opacity-70
            p-4  /* Slight padding so content doesn't touch screen edges */
          `}
          onClick={() => setSelectedLightbox(null)}
        >
          <div
            // Make container scrollable if content is large
            className={`
              relative w-full
              md:max-w-3xl  /* On larger screens, limit width */
              bg-transparent 
              overflow-y-auto max-h-screen
              flex flex-col items-center
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setSelectedLightbox(null)}
              className={`
                absolute top-2 right-2 sm:top-4 sm:right-4
                text-white hover:text-retroPink 
                focus:outline-none text-2xl 
              `}
              aria-label="Close"
            >
              &#x2715;
            </button>
            <img
              src={selectedLightbox.src}
              alt={selectedLightbox.title || 'Lightbox Image'}
              className={`
                object-contain 
                w-auto max-w-full  /* Let it shrink to fit mobile screens */
                max-h-[80vh] 
                rounded-md shadow-2xl
                transform transition-transform duration-300
                hover:scale-105
              `}
            />

            {/* Optional title/description in the lightbox */}
            {selectedLightbox.title && (
              <h4 className="mt-4 text-white text-2xl font-heading text-center px-2">
                {selectedLightbox.title}
              </h4>
            )}
            {selectedLightbox.description && (
              <p className="text-retroGray text-lg text-center mt-2 px-4 max-w-xl">
                {selectedLightbox.description}
              </p>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default KeyOfferings;